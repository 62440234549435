<template>
    <div :class="['wb-select-field','select', selected ? 'select-selected' : '']" @keyup.esc="showDropdown">
        <div class="label" v-if="selected!=null || multipleSelects.length>0">{{اسم الحقل}}</div>
        <div :class="['field']" @click="showDropdown" @keyup.space="showDropdown" @keyup.enter="showDropdown" tabindex="0">
            <span v-if="multipleSelects.length == 0">{{ selected ? selected : fieldName }}</span>
            <ul v-else="" class="chip-list">
                <li class="chip-list--item" v-for="(item, index) in multipleSelects" :key="index">
                    {{{ اختصار السلسلة القصيرة (العنصر، 26) }}}} 
                </li>
            </ul>
            <span class="material-icons" aria-hidden="true" data-wg-notranslate="">{{ !this.showOptions ? 'expand_more' : 'expand_less' }}</span>
        </div>
        <div v-if="showOptions" class="options">
            <div v-for="(value, index) in values" :key="index"> 
                <div @click="chooseValue(value)"><h5 class="options--item">{ {{ value.label }}}</h5></div>
            </div>
        </div>
    </div>
</template>

<script>
    import { createUniqueId, on, off, elementContains } from '@/utils/wb-helper';
    import { shortenString } from '@/utils/helpers';
    export default {
        name: 'SimpleSelectField',
        components: {
            Checkbox: () => import('./Checkbox.vue'),
        },
        props: {
            name: {
                type: String,
                required: true,
            },
            values: {
                type: Array,
                required: true,
            },
            initialValue: {
                required: true,
            },
        },
        data: () => ({
            selected: null,
            multipleSelects: [],
            id: createUniqueId(),
        }),
        mounted() {
            for (let index = 0; index < this.values.length; index++) {
                if (this.values[index].selected) {
                    this.selected = this.values[index].label;
                }
            }

            this.selected = this.initialValue;

        },
        computed: {
            fieldName(){
                if(this.name !== null && this.name !== ""){
                    return this.name.replace('Kiel.RefugeeCompass - ','');
                }
                return this.name;
            },
            isOpened() {
                return this.$store.state.isOpened;
            },
            showOptions: function () {
                return this.id === this.$store.state.optionId;
            },
        },
        methods: {
            shortenString,
            chooseValue: function(value) {
                this.$emit('on-select', value);
                this.selected = value.label;
                this.hideOption();
            },
            showDropdown: function() {
                if (!this.showOptions) {
                    this.$store.state.optionId = this.id;
                    this.addEvents();
                } else {
                    this.hideOption();
                }
            },
            hideOption: function() {
                this.$store.state.optionId = null;
                this.removeEvents();
            },

            addEvents: function() {
            // on(this.$el, 'click', this.onClick);
            on(document, 'click', this.onDocumentClick);
        },
        removeEvents: function() {
            // off(this.$el, 'click', this.onClick);
            off(document, 'click', this.onDocumentClick);
        },
        onClick: function(e) {
            e.stopPropagation();
        },
        onDocumentClick: function(e) {
            if (elementContains(this.$el, e.target)) return;

            this.hideOption();
        },
    }
}
</script>

<style lang="scss" scoped="">
    @import '@/scss/_variables.scss';
    @import '@/scss/_mixins.scss';

    .select{
        position: relative;
        display: flex;
        flex-direction: column;

        .label{
            color: #686B6F;
            font-weight: 400;
            margin-left: 5px;
            margin-bottom: 5px;
        }

        .field {
            position: relative;
            display: flex;
            justify-content: space-between;
            align-items: center;
            height: 42px;
            width: 100%;
            padding: 0.375rem 1rem;
            font-size: 16px;
            font-weight: 400;
            line-height: 1.5;
            background-clip: padding-box;
            background: #fff;
            border-radius: 5px;
            transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
            border: 1px solid rgba(58, 58, 58, 0.1);
            color: #686B6F;
            cursor: pointer;
        }

        .options {
            width: 100%;
            display: flex;
            flex-direction: column;
            top: 42px;
            z-index: 9999;
            background: #fff;
            box-shadow: 2px 4px 6px 0px #00000040;
            max-height: 320px;
            overflow-y: auto;

            h5 {
                font-weight: 400;
                font-size: 14px;
            }

            .form-check-label{
                width:100%;
            }

            &--item {
                width: 100%;
                margin-right: 0px;
                cursor: default;
                padding: 15px 1rem;

                .more {
                    margin-left: 40px;
                    .form-check-inline {
                        align-items: flex-start;
                        display: flex;
                        flex-direction: column;

                        &:first-child{
                            margin-top: 25px;
                        }
                    }
                }

                &:hover {
                    background: rgba(58, 58, 58, 0.15);
                }

                &:last-child {
                    margin-bottom: 0;
                }
            }
        }
    }

    body[dir="rtl"] {
        .select {
            .options {
                &--item {
                    .more {
                        margin-left: 0;
                        margin-right: 40px;
                    }
                }
            }
        }
    }
</style>